import React, {Component} from 'react';
import HeaderNav from './HeaderNav.js'
import Animation from './Animation';
import Background from './Background';
import external from './../assets/external-link.png';
import { Link, withRouter } from 'react-router-dom';
import pic1 from './../assets/about_1.png';
import pic2 from './../assets/about_2.png';
import pic3 from './../assets/about_3.png';




import './../styles/App.scss';
import './../styles/Home.scss';


class Home extends Component {

    state = {
        innerWidth: 0,
        innerHeight: 0,
        blogHovered: false
    }

    blogHandlerEnter = () => {
        this.setState({
            blogHovered: !this.state.blogHovered
        }, () => {
            console.log("entered: ", this.state);
        });
    }

    blogHandlerLeave = () => {
        this.setState({
            blogHovered: !this.state.blogHovered
        }, () => {
            console.log("left: ", this.state);
        });
    }

  render() {
      console.log("home render", this);
      let blogClass = "xyz";
      if(this.state.blogHovered) {
          blogClass += " showup";
      }

      return (
          <section className="home container">
            {/* <!-- Big thanks to Jonathan Yang + Shiva Vemireddy for looking over iterations of this <3  --> */}
            <HeaderNav home={true} blogHandlerEnter={this.blogHandlerEnter} blogHandlerLeave={this.blogHandlerLeave} />
            <h2>i'm adhiv dhar. welcome to my corner of the web 💿</h2>
            <p>I live in brooklyn, new york. I'm a creative with a technical background that produces <a href="http://youtube.com/@dharbeats" target="_blank">music</a> and builds <a href="http://mindgarden.page/" target="_blank">websites</a>. I just released my first beat tape <a href="https://youtu.be/SkyfCY1tUwA" target="_blank">TINO</a>, sampling vintage bollywood music. I'm <Link to="/now">currently</Link> working on music production full time and doing freelance website development.</p>
            
            

            
          </section>
      );
  }
}

export default Home;
